var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"form-page-section section"},[_c('div',{staticClass:"form-page-container container"},[_c('h1',{staticClass:"pass-page-ttl title is-1 is-size-3-mobile has-text-weight-normal",attrs:{"data-test":"forget-password-title"}},[_vm._v("ワーカーズフォーラムMOC会員　パスワード再設定")]),_c('StepNavi',{attrs:{"data":_vm.stepNavi,"active":"0"}}),_vm._m(0),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.errorMessage),expression:"errorMessage"}],ref:"errorMessage",staticClass:"login-page-error-top is-size-6-mobile has-text-danger has-text-weight-bold has-text-centered py-6"},[_vm._v("入力内容に誤りがあるか登録されていません。"),_c('br'),_vm._v("ご確認の上、再度入力してください。")]),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ObserverProps){return [_c('form',{attrs:{"action":"","novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('h2',{staticClass:"form-page-ttl03 title is-3 has-text-secondary",attrs:{"data-test":"forget-password-title2"}},[_vm._v(" 会員様情報 ")]),_c('table',{staticClass:"form-page-table"},[_c('tr',{staticClass:"form-page-table-tr",attrs:{"data-test":"form-row-name"}},[_c('th',{staticClass:"form-page-table-th"},[_c('div',{staticClass:"is-flex is-align-items-center is-flex-wrap-wrap"},[_c('span',{staticClass:"form-page-table-th-text is-block has-text-weight-bold",attrs:{"data-test":"form-row-heading"}},[_vm._v("お名前")]),_c('span',{staticClass:"form-label-required tag is-required",attrs:{"data-test":"form-row-require"}},[_vm._v("必須")])])]),_c('td',{staticClass:"form-page-table-td"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var errors = ref.errors;
return [_c('div',{staticClass:"form-page-table-td-box is-flex is-align-items-center"},[_c('div',{staticClass:"form-page-table-name is-flex is-align-items-center"},[_c('div',{staticClass:"form-page-table-name-text is-flex-shrink-0",attrs:{"data-test":"form-label-sei"}},[_vm._v("姓")]),_c('validation-provider',{attrs:{"rules":_vm.ruleAccountNameSei,"vid":"name1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name1),expression:"name1"}],staticClass:"input form-page-table-name-input",class:{ 'is-danger' : errors.length > 0},attrs:{"type":"text","name":"姓","data-test":"input-name1"},domProps:{"value":(_vm.name1)},on:{"input":function($event){if($event.target.composing){ return; }_vm.name1=$event.target.value}}})]}}],null,true)})],1),_c('div',{staticClass:"form-page-table-name is-flex is-align-items-center"},[_c('div',{staticClass:"form-page-table-name-text is-flex-shrink-0",attrs:{"data-test":"form-label-mei"}},[_vm._v("名")]),_c('validation-provider',{attrs:{"rules":_vm.ruleAccountNameMei,"vid":"name2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name2),expression:"name2"}],staticClass:"input form-page-table-name-input",class:{ 'is-danger' : errors.length > 0},attrs:{"type":"text","name":"名","data-test":"input-name2"},domProps:{"value":(_vm.name2)},on:{"input":function($event){if($event.target.composing){ return; }_vm.name2=$event.target.value}}})]}}],null,true)})],1)]),(invalid)?_c('div',{staticClass:"has-text-danger has-text-weight-bold mt-1",attrs:{"data-test":"input-error-name"}},[_vm._l((errors.name1),function(item){return _c('div',[_vm._v(_vm._s(item))])}),_vm._l((errors.name2),function(item){return _c('div',[_vm._v(_vm._s(item))])})],2):_vm._e()]}}],null,true)})],1)]),_c('tr',{staticClass:"form-page-table-tr",attrs:{"data-test":"form-row-company"}},[_c('th',{staticClass:"form-page-table-th"},[_c('div',{staticClass:"is-flex is-align-items-center is-flex-wrap-wrap"},[_c('span',{staticClass:"form-page-table-th-text is-block has-text-weight-bold",attrs:{"data-test":"form-row-heading"}},[_vm._v("企業名")]),_c('span',{staticClass:"form-label-required tag is-required",attrs:{"data-test":"form-row-require"}},[_vm._v("必須")])])]),_c('td',{staticClass:"form-page-table-td"},[_c('div',{staticClass:"form-page-table-td-box"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var errors = ref.errors;
return [_c('vue-simple-suggest',{ref:"suggest",attrs:{"list":_vm.suggestionList,"max-suggestions":100,"min-length":0,"filter-by-query":true},model:{value:(_vm.companyName),callback:function ($$v) {_vm.companyName=$$v},expression:"companyName"}},[_c('validation-provider',{attrs:{"rules":_vm.ruleAccountCompanyName,"vid":"company","name":"企業名"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"select form-page-select is-block"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.companyName),expression:"companyName"}],staticClass:"form-page-select-company",class:{ 'is-danger' : errors.length > 0},attrs:{"name":"企業名","data-test":"input-company"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.companyName=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":""}},[_vm._v("選択してください")]),_vm._l((_vm.companyOptions),function(data){return _c('option',{domProps:{"value":data}},[_vm._v(_vm._s(data))])})],2)])]}}],null,true)})],1),(invalid)?_c('div',{staticClass:"has-text-danger has-text-weight-bold mt-1"},_vm._l((errors.company),function(item){return _c('div',[_vm._v(_vm._s(item))])}),0):_vm._e()]}}],null,true)})],1)])]),_c('tr',{staticClass:"form-page-table-tr",attrs:{"data-test":"form-row-email"}},[_c('th',{staticClass:"form-page-table-th"},[_c('div',{staticClass:"is-flex is-align-items-center is-flex-wrap-wrap"},[_c('span',{staticClass:"form-page-table-th-text is-block has-text-weight-bold",attrs:{"data-test":"form-row-heading"}},[_vm._v("メールアドレス")]),_c('span',{staticClass:"form-label-required tag is-required",attrs:{"data-test":"form-row-require"}},[_vm._v("必須")])])]),_c('td',{staticClass:"form-page-table-td"},[_c('div',{staticClass:"form-page-table-td-box"},[_c('validation-provider',{attrs:{"rules":_vm.ruleForegetAccountMail,"vid":"mail"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.mail),expression:"mail"}],staticClass:"input",class:{ 'is-danger' : errors.length > 0},attrs:{"type":"email","placeholder":"メールアドレスをご記入ください","name":"メールアドレス","data-test":"input-email"},domProps:{"value":(_vm.mail)},on:{"input":function($event){if($event.target.composing){ return; }_vm.mail=$event.target.value}}}),_c('span',{staticClass:"has-text-danger has-text-weight-bold mt-1",attrs:{"data-test":"input-error-email"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])]),_c('tr',{staticClass:"form-page-table-tr",attrs:{"data-test":"form-row-secret"}},[_c('th',{staticClass:"form-page-table-th"},[_c('div',{staticClass:"is-flex is-align-items-center is-flex-wrap-wrap"},[_c('span',{staticClass:"form-page-table-th-text is-block has-text-weight-bold",attrs:{"data-test":"form-row-heading"}},[_vm._v("秘密の質問の答え")]),_c('span',{staticClass:"form-label-required tag is-required",attrs:{"data-test":"form-row-require"}},[_vm._v("必須")])])]),_c('td',{staticClass:"form-page-table-td"},[_c('p',{staticClass:"has-text-weight-bold mb-3"},[_vm._v("卒業した小学校は？")]),_c('div',{staticClass:"form-page-table-td-box"},[_c('validation-provider',{attrs:{"rules":_vm.ruleAccountSecretQuestAans},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.secretQuestAans),expression:"secretQuestAans"}],staticClass:"input",class:{ 'is-danger' : errors.length > 0},attrs:{"type":"text","name":"秘密の質問の答え","data-test":"input-secret","maxlength":"10"},domProps:{"value":(_vm.secretQuestAans)},on:{"input":function($event){if($event.target.composing){ return; }_vm.secretQuestAans=$event.target.value}}}),_c('span',{staticClass:"has-text-danger has-text-weight-bold mt-1",attrs:{"data-test":"input-error-secret"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('p',{staticClass:"form-page-table-attention is-size-8 mt-2 has-text-red",attrs:{"data-test":"attention"}},[_vm._v("「秘密の質問の答え」を登録していない、または登録した覚えがない場合は、空欄のまま送信してください。")]),_c('p',{staticClass:"form-page-table-attention is-size-8 has-text-red",attrs:{"data-test":"attention"}},[_vm._v("「秘密の質問の答え」を忘れた場合は、[お名前]と[企業名]を記載の上、こちら(info_wf-moc@mfbm.co.jp)までご連絡ください。")])])])]),_c('div',{staticClass:"is-flex is-justify-content-center mt-6"},[_c('button',{staticClass:"form-page-send-btn is-fullwidth button is-medium is-rounded is-primary",attrs:{"type":"submit","data-test":"next-btn"}},[_vm._v("送信")])])])]}}])}),_c('div',{staticClass:"has-text-centered mt-5 pt-4"},[_c('a',{attrs:{"data-test":"back-btn"},on:{"click":function($event){$event.preventDefault();return _vm.pageBack.apply(null, arguments)}}},[_vm._v("前の画面へ戻る")])])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"pass-page-lead has-text-centered",attrs:{"data-test":"forget-password-lead"}},[_vm._v("登録されているメールアドレス宛に"),_c('br',{staticClass:"is-hidden-tablet"}),_vm._v("「パスワード再設定メール」をご案内します。"),_c('br'),_vm._v("ご登録済みの会員情報をご入力の上、"),_c('br',{staticClass:"is-hidden-tablet"}),_vm._v("[送信]ボタンを押してください。")])}]

export { render, staticRenderFns }