



import { VeeValidateConfig } from 'vee-validate/dist/types/config';
import { Component, Vue, Emit } from 'vue-property-decorator';
import store from '../../../store';
import VueRouter, { Route } from 'vue-router';
import VeeValidate from '../../../plugins/vee-validate';
import { PasswordReminder, PostLoginResponse, MailaddressEdit, ErrorResponse } from '../../../../types/typescript-axios/api';
import StepNavi from '../../../components/contents/stepNavi/StepNavi.vue';
import axios, {AxiosResponse, AxiosError} from 'axios';
import VueSimpleSuggest from 'vue-simple-suggest';
import 'vue-simple-suggest/dist/styles.css';
import { getSuggestionList } from '@/plugins/helper';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
]);

@Component({
  mixins: [ VeeValidate ],
  components: {
    StepNavi,
    VueSimpleSuggest,
  },
})
export default class LoginForgetPassword extends Vue {
  private errorMessage: boolean = false;

  private companyNames: string[] = [];

  private name1: string = '';
  private name2: string = '';
  private companyName: string = '';
  private companyOptions: string[] = [];
  private mail: string = '';
  private secretQuestAans: string = '';

  private stepNavi: StepNaviData[] = [
    {
      title: '会員情報入力',
    },
    {
      title: '再設定メール送信',
    },
  ];


  public async onSubmit() {
    const $observer: any = this.$refs.observer;
    $observer.reset();
    $observer.validate().then((result: boolean) => {
      const params: PasswordReminder = {};
      params.lastname = this.name1;
      params.firstname = this.name2;
      params.comp_nm = this.companyName;
      params.mail_ad = this.mail;
      params.secret_quest_ans = this.secretQuestAans;
      if (result) {
        store.dispatch('account/authForgetPasswordMail', params)
        .then((response: any) => {
          if (response.data.success) {
            this.$router.push('complete/');
          } else {
            this.errorMessage = true;
            Vue.nextTick()
            .then(() => {
              const errorDome: any = this.$refs.errorMessage;
              window.scrollTo({
                top: window.pageYOffset + errorDome.getBoundingClientRect().top,
                behavior: 'smooth',
              });
            });
          }
        }, (error: AxiosError<ErrorResponse>) => {
          const errorRes: any = error.response;
          if (typeof errorRes.data.code !== 'undefined' && errorRes.data.code === 'rest_forbidden') {
            this.$router.push('error/');
            return;
          }
          if (errorRes.status === 401 && errorRes.data.errors.code === 0) {
            this.$router.push('error/');
            return;
          }
          this.errorMessage = true;
          const errorDome: any = this.$refs.errorMessage;
          window.scrollTo({
            top: window.pageYOffset + errorDome.getBoundingClientRect().top,
            behavior: 'smooth',
          });
        });
      } else {
        this.errorMessage = true;
        Vue.nextTick()
        .then(() => {
          const errorDome: any = this.$refs.errorMessage;
          window.scrollTo({
            top: window.pageYOffset + errorDome.getBoundingClientRect().top,
            behavior: 'smooth',
          });
        });
      }
    });
  }
  public async pageBack() {
    window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/');
  }

  @Emit()
  private suggestOff() {
    const ref: any = this.$refs;
    ref.suggest.hideList();
  }

  @Emit()
  private suggestOn() {
    const ref: any = this.$refs;
    ref.suggest.showList();
  }

  @Emit()
  private companyBlur() {
    window.setTimeout(() => this.suggestOff(), 300);
  }

  @Emit()
  private async suggestionList() {
    return await axios.get('/wp-json/moc/company')
    .then((res) => this.companyNames = res.data )
    .catch( (e: AxiosError<ErrorResponse>) => {
      console.log(e);
    });
  }

  private async beforeRouteEnter(to: Route, from: Route, next: any) {
    const resCompany = await axios.get('/wp-json/moc/company');
    next((vm: {companyOptions: string[]}) => {
      vm.companyOptions = resCompany.data;
    });
  }

}
